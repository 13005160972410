
import { Vue, Component, Prop } from 'vue-property-decorator'
import MainWithSidePanelLayout from '@/layouts/MainWithSidePanelLayout.vue'
import MainWithSidePanelLayoutNavigation from '@/components/MainWithSidePanelLayoutNavigation.vue'
import OrganizationTeams from '@/components/OrganizationTeams.vue'
import OrganizationRiders from '@/components/OrganizationRiders.vue'
import OrganizationRiderData from '@/components/OrganizationRiderData.vue'
import customerAccount from '@/services/customerAccount'
import auth from '@/store/modules/auth'
import { Team } from '@/models/dto'
import { NavigationLink } from '@/models/NavigationLink'
import systemParameter from '@/store/modules/systemParameter'
import OrganizationAccount from '@/components/OrganizationAccount.vue'
import SharedContacts from '@/components/SharedContacts.vue'

@Component({
  components: {
    MainWithSidePanelLayout,
    MainWithSidePanelLayoutNavigation,
    OrganizationTeams,
    SharedContacts,
    OrganizationRiders,
    OrganizationAccount,
  },
})
export default class Organization extends Vue {
  customerAccount = null
  teams: Team[] = []

  get isEnterpriseAccount(): boolean {
    if (!this.customerAccount) {
      return false
    }
    return !this.customerAccount?.parentCustomerAccountId
  }

  get customerAccountName(): string {
    return this.customerAccount?.name || ''
  }

  get component(): any {
    return (
      this.links.find((component) => this.$route.name === component.name)
        ?.value || null
    )
  }

  get hasRiderTracking(): boolean {
    return this.customerAccount?.hasRiderTracking
  }

  get links(): NavigationLink[] {
    return [
      {
        value: OrganizationAccount,
        name: 'organization-account',
        icon: '',
        label: this.$t('accountItems.ACCOUNT'),
      },
      {
        value: OrganizationTeams,
        name: 'organization-teams',
        icon: '',
        label: this.$t('accountItems.USERS_AND_TEAMS'),
      },
      {
        value: SharedContacts,
        name: 'shared-contacts',
        icon: '',
      },
      {
        value: OrganizationRiders,
        name: 'organization-riders',
        icon: '',
        hide: !this.hasRiderTracking,
      },
      {
        value: OrganizationRiderData,
        name: 'organization-rider-data',
        icon: '',
        hide: !this.hasRiderTracking,
      },
    ].filter((link) => !link.hide)
  }

  async mounted(): Promise<void> {
    await this.loadData()
  }

  async loadData(): Promise<void> {
    if (!auth.customerAccount.customerAccountId) {
      return
    }
    const { data } = await customerAccount.byId(
      auth.customerAccount.customerAccountId
    )
    this.customerAccount = data
    const teams = data.teams as Team[]
    teams.unshift({
      ...data,
      isParent: true,
    })
    this.teams = teams
  }
}
